<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="container">
      <div class="wrapper">
        <div class="content-wrapper">
          <div class="card-body">
            <div class="card pt-2 p-2">
              <div class="row">
                <div class="col-md-8" v-if="formEnvio.tipo_producto_id == 1">
                  <h3 style="font-size: 15px; text-align: center">
                    <strong>
                      GUIA UNICA PARA TRANSPORTAR PETROLEO CRUDO
                      <br />PETROMINERALES COLOMBIA CORP. SUCURSAL COLOMBIA
                    </strong>
                  </h3>
                </div>
                <div class="col-md-8" v-else>
                  <h3 style="font-size: 15px; text-align: center">
                    <strong>
                      GUIA PARA TRANSPORTAR PRODUCTOS DERIVADOS DEL PETROLEO
                      <br />FRONTERA ENERGY COLOMBIA CORP.
                    </strong>
                  </h3>
                </div>
                <div class="col-md-2 float-md-right">
                  <h6>
                    <span class="badge badge-success" v-if="estadoGuia == 1"
                      >Estado: Disponible</span
                    >
                    <span class="badge badge-primary" v-if="estadoGuia == 2"
                      >Estado: Usada</span
                    >
                    <span class="badge badge-warning" v-if="estadoGuia == 6"
                      >Estado: Sin Imprimir</span
                    >
                  </h6>
                </div>
                <div class="col-md-2" v-if="accion == 'Cambiar'">
                  <span class="badge badge-warning"
                    >N° GUIA ANTERIOR:<br />
                    {{ formEnvio.guia.numero }}-{{
                      formEnvio.guia.digito_verificacion
                    }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mt-4">
                  <img
                    src="/img/frontera-pegaso-ermes.png"
                    style="width: 280px"
                  />
                </div>
                <div class="col-md-8">
                  <div class="row ml-3">
                    <div class="col-md-2 text-center">
                      <label for>TERCERO</label>
                      <input
                        v-model="tercero"
                        type="checkbox"
                        value="1"
                        class="form-control form-control-sm"
                        @change="buscarGuiaInput()"
                        :disabled="formEnvio.tipo_act === 4"
                      />
                    </div>
                    <!-- <div class="col-md-2 pt-4">
                      <div class="form-group">
                        <div
                          class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="hasGuias"
                            v-model="hasGuias"
                            :checked="formEnvio.guia_id"
                            :disabled="tercero == 0"
                          />
                          <label
                            class="custom-control-label"
                            for="hasGuias"
                          ></label>
                        </div>
                      </div>
                    </div> -->

                    <div class="col-md-4" v-if="accion != 'Cambiar'">
                      <label for="nGuia">N° GUIA</label>
                      <input
                        v-if="!hasGuias"
                        disabled="disabled"
                        v-model="form.numeroGuia"
                        style="color: #ff0000; font-weight: bold"
                        class="form-control form-control-sm"
                      />
                    </div>
                    <div class="col-md-4" v-else>
                      <label for="nGuia">N° GUIA</label>
                      <v-select
                        :class="[
                          $v.formEnvio.guia_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="guia"
                        placeholder="Guia"
                        label="numero"
                        :options="listasForms.guias"
                        @input="selectGuia()"
                        class="form-control form-control-sm p-0"
                        :disabled="accion == 'Show' && estadoGuia !== 6"
                      ></v-select>
                    </div>
                    <div
                      class="col-md-2"
                      v-if="
                        (accion == 'Diligenciar' &&
                          digito_verificacion === null) ||
                          (accion == 'Cambiar' &&
                            digito_verificacion === null &&
                            Object.keys(guia).length > 0)
                      "
                    >
                      <label for="digito_verificacion">DV</label>
                      <input
                        v-model="formEnvio.digito_verificacion"
                        style="color: #ff0000; font-weight: bold"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.digito_verificacion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="producto">TIPO PRODUCTO</label>
                      <br />
                      <select
                        class="form-control form-control-sm"
                        id="tipo_prod"
                        v-model="formEnvio.tipo_producto_id"
                        :class="
                          $v.formEnvio.tipo_producto_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="
                          (accion == 'Show' && estadoGuia !== 6) ||
                            formEnvio.tipo_act == 4
                        "
                        @change="buscarGuiaInput()"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="op in listasForms.tipo_producto"
                          :key="op.id"
                          :value="op.id"
                        >
                          {{ op.nombre }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div
                  class="row mb-2"
                  style="font-size: 12px"
                  v-if="accion == 'Cambiar'"
                >
                  <div class="col-sm-12">
                    <label for="motivo">MOTIVO ANULACIÓN:</label>
                    <textarea
                      v-model="formEnvio.motivo_anulacion"
                      placeholder="Descrición del Motivo"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.motivo_anulacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                </div>
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-sm-6">
                    <label for="fecha_expedicion">LUGAR DE EXPEDICION</label>
                    <input
                      v-model="form.ciudadOrigen"
                      type="text"
                      style="font-size: 13px"
                      placeholder="Lugar"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="fecha_expedicion1"
                      >FECHA Y HORA DE EXPEDICION</label
                    >
                    <div class="row ml-1">
                      <input
                        type="date"
                        v-model="formEnvio.fecha_expedicion1"
                        style="font-size: 13px"
                        class="form-control form-control-sm col-md-6"
                        @input="validaFechas(1)"
                        :class="
                          $v.formEnvio.fecha_expedicion1.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                      <input
                        v-model="formEnvio.hora_expedicion"
                        type="text"
                        @input="validaFechas(1)"
                        class="form-control form-control-sm col-md-6"
                        style="font-size: 13px"
                        v-mask="{
                          mask: '99:99',
                          hourFormat: '24',
                        }"
                        :class="
                          $v.formEnvio.hora_expedicion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="row" style="font-size: 12px">
                  <div
                    class="form-group"
                    :class="
                      sitioOrigen &&
                      sitioOrigen.check_tanque &&
                      formEnvio.tanque_id
                        ? 'col-md-4'
                        : 'col-md-6'
                    "
                  >
                    <label for="campo_productor"
                      >PLANTA O CAMPO PRODUCTOR</label
                    >
                    <select
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      id="prod_liquid"
                      v-model="formEnvio.producto_liquido_id"
                      @change="selectProductoLiquido()"
                      :class="
                        $v.formEnvio.producto_liquido_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    >
                      <option value>Seleccione...</option>
                      <option
                        v-for="pl in listasForms.producto_liquido"
                        :key="pl.id"
                        :value="pl.id"
                      >
                        {{ pl.nombre }}
                        {{ pl.codigo ? "- (" + pl.codigo + ")" : "" }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group"
                    :class="
                      sitioOrigen &&
                      sitioOrigen.check_tanque &&
                      formEnvio.tanque_id
                        ? 'col-md-4'
                        : 'col-md-6'
                    "
                  >
                    <label for="remision">FACTURA O REMISION N°</label>
                    <input
                      type="text"
                      v-model="formEnvio.remision"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      placeholder="Orden de Cargue"
                      :class="
                        $v.formEnvio.remision.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div
                    v-show="
                      sitioOrigen &&
                        sitioOrigen.check_tanque &&
                        formEnvio.tanque_id
                    "
                    class="form-group col-md-4"
                  >
                    <label>TANQUE</label>
                    <select
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.tanque_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      style="font-size: 13px"
                      v-model="formEnvio.tanque_id"
                      disabled
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="t in listasForms.tanques"
                        :key="t.id"
                        :value="t.id"
                      >
                        {{ t.tanque }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-8">
                    <label for="fecha_expedicion">DESPACHADO A:</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="formEnvio.despachado_a_id"
                      :class="
                        $v.formEnvio.despachado_a_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="
                        formEnvio.sitio_destino_id == null ||
                          (ruta && ruta.rutas_despachados.length == 0) ||
                          ruta == undefined
                      "
                    >
                      <option value>Seleccione...</option>
                      <option
                        v-for="despachado in listasForms.despachado_a"
                        :key="despachado.numeracion"
                        :value="despachado.numeracion"
                      >
                        {{ despachado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="campo_productor">CODIGO</label>
                    <input
                      type="text"
                      v-model="formEnvio.codigo_destino"
                      style="font-size: 13px"
                      placeholder="Codigo Dane"
                      class="form-control form-control-sm"
                      :disabled="formEnvio.tipo_producto_id !== 10"
                    />
                  </div>
                  <div class="form-group col-md-8">
                    <label for="direccion_destino">DIRECCION:</label>
                    <input
                      type="text"
                      v-model="formEnvio.direccion_destino"
                      style="font-size: 13px"
                      placeholder="Dirección"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="campo_productor">CIUDAD</label>
                    <input
                      type="text"
                      v-model="form.ciudadDestino"
                      style="font-size: 13px"
                      placeholder="Ciudad"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-8">
                    <label for="conductor">NOMBRE DEL CONDUCTOR</label>
                    <input
                      type="text"
                      v-model="conductor.nombre"
                      style="font-size: 13px"
                      placeholder="Nombre Conductor"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="campo_productor">CEDULA</label>
                    <input
                      v-if="acto == 3"
                      type="text"
                      v-model="formEnvio.cedula_conductor"
                      style="font-size: 13px"
                      placeholder="Cedula Conductor"
                      label="cedula"
                      class="form-control form-control-sm"
                      @change="buscarConductores()"
                      :class="
                        $v.formEnvio.conductor_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="acto != 3"
                    />
                    <input
                      v-else
                      type="text"
                      v-model="formEnvio.cedula_conductor"
                      style="font-size: 13px"
                      placeholder="Cedula Conductor"
                      label="cedula"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="empresa_transportadora"
                      >EMPRESA TRANSPORTADORA</label
                    >
                    <input
                      type="text"
                      v-model="transportadora.razon_social"
                      style="font-size: 13px"
                      placeholder="Empresa Transportadora"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="cabezote">PLACAS DEL CABEZOTE</label>
                    <input
                      v-if="acto == 3"
                      type="text"
                      v-model="vehiculo.placa"
                      style="font-size: 13px"
                      placeholder="Placa Vehículo"
                      class="form-control form-control-sm"
                      @change="buscarVehiculos()"
                      :class="
                        $v.formEnvio.vehiculo_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="acto != 3"
                    />
                    <input
                      v-else
                      type="text"
                      v-model="vehiculo.placa"
                      style="font-size: 13px"
                      placeholder="Placa Vehículo"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="remolque">PLACAS DEL TANQUE</label>
                    <input
                      v-if="acto == 3"
                      type="text"
                      v-model="remolque.placa"
                      style="font-size: 13px"
                      placeholder="Placa Remolque"
                      class="form-control form-control-sm"
                      @change="buscarRemolques()"
                      :class="
                        $v.formEnvio.remolque_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="acto != 3"
                    />
                    <input
                      v-else
                      type="text"
                      v-model="remolque.placa"
                      style="font-size: 13px"
                      placeholder="Placa Remolque"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-6">
                    <label for="lugar_origen">LUGAR DE ORIGEN</label>
                    <strong
                      v-if="sitioOrigen && sitioOrigen.codigo"
                      class="text-success float-right mr-3"
                      >Código: {{ sitioOrigen.codigo }}</strong
                    >
                    <v-select
                      class="form-control form-control-sm p-0"
                      :class="[
                        $v.formEnvio.sitio_origen_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="sitioOrigen"
                      placeholder="Sitio Origen"
                      label="nombre_sigla"
                      :options="listasForms.sitiosOrigenes"
                      @search="buscarSitioOrigen"
                      @input="selectSitioOrigen()"
                      :disabled="
                        (accion == 'Show' && estadoGuia !== 6) ||
                          formEnvio.tipo_act == 4
                      "
                    ></v-select>
                    <div class="error" v-if="!formEnvio.sitio_origen_id">
                      Seleccione sitio de origen
                    </div>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="fecha_cargue">FECHA</label>
                    <input
                      v-model="formEnvio.fecha_cargue"
                      type="date"
                      @input="validaFechas(2)"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="hora_cargue">HORA DE SALIDA</label>
                    <input
                      v-model="formEnvio.hora_cargue"
                      type="text"
                      @input="validaFechas(2)"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                      :class="
                        $v.formEnvio.hora_cargue.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="lugar_destino">LUGAR DE DESTINO</label>
                    <strong
                      v-if="sitioDestino && sitioDestino.codigo"
                      class="text-success float-right mr-3"
                      >Código: {{ sitioDestino.codigo }}</strong
                    >
                    <v-select
                      :class="[
                        $v.formEnvio.sitio_destino_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="sitioDestino"
                      placeholder="Sitio Destino"
                      label="nombre_sigla"
                      :options="listasForms.sitiosDestinos"
                      :disabled="
                        formEnvio.producto_liquido_id == null ||
                          (formEnvio.producto_liquido_id == undefined &&
                            formEnvio.sitio_origen_id == null) ||
                          (accion == 'Show' && estadoGuia !== 6)
                      "
                      @search="buscarSitioDestino"
                      @input="selectSitioDestino()"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                    <div class="error" v-if="!formEnvio.sitio_origen_id">
                      Seleccione sitio de origen
                    </div>
                    <div class="error" v-else-if="!formEnvio.sitio_destino_id">
                      Seleccione sitio de destino
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="hora_vigencia">HORAS DE VIGENCIA</label>
                    <input
                      v-model="formEnvio.horas_vigencia"
                      type="text"
                      style="font-size: 13px"
                      placeholder="Horas Vigencia"
                      class="form-control form-control-sm"
                      disabled="disabled"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="hora_adicional">HORA ADICIONAL</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="formEnvio.hora_adicional"
                      :disabled="
                        !formEnvio.horas_vigencia ||
                          (accion == 'Show' && estadoGuia !== 6)
                      "
                    >
                      <option value>Seleccione...</option>
                      <option
                        v-for="horaAdicional in listasForms.horasAdicionales"
                        :key="horaAdicional.descripcion"
                        :value="horaAdicional.descripcion"
                      >
                        {{ horaAdicional.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group col-md-12"
                    v-if="accion == 'Cambiar' && cambio_destino"
                  >
                    <label for="hora_adicional">RUTA A RECONOCER</label>
                    <v-select
                      v-model="rutaA"
                      placeholder="Ruta"
                      label="nombre"
                      :options="listasForms.rutasA"
                      @input="selectRutaA()"
                      :class="[
                        $v.formEnvio.ruta_a_conocer_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ]"
                    ></v-select>
                  </div>
                  <div
                    class="form-group col-md-10"
                    v-if="accion == 'Cambiar' && cambio_destino"
                  >
                    <label for="concepto">CONCEPTO</label>
                    <textarea
                      v-model="formEnvio.concepto"
                      style="font-size: 12px"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.concepto.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <div
                    class="form-group col-md-2"
                    v-if="accion == 'Cambiar' && cambio_destino"
                  >
                    <label for="tramo">TRAMO</label>
                    <input
                      type="number"
                      v-model="formEnvio.tramo"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.tramo.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div
                    v-if="ruta && sitioOrigen && sitioDestino"
                    class="form-group text-center text-muted col-md-12"
                  >
                    <strong class="text-lg"
                      >{{ ruta.nombre }}
                      <small v-if="ruta.codigo"
                        >({{ ruta.codigo }})</small
                      ></strong
                    >
                    <div>Ruta</div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="col-md-1 text-center">
                    <label for>TERCERO</label>
                    <input
                      v-model="terceroSellos"
                      type="checkbox"
                      value="1"
                      class="form-control form-control-sm"
                      @change="buscarSellos()"
                    />
                  </div>
                  <div class="form-group col-md-11">
                    <label for="sellos">DESCRIPCION DEL PRODUCTO</label>
                    <v-select
                      :class="[
                        $v.formEnvio.sellos.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="formEnvio.sellos"
                      placeholder="Sellos"
                      label="numero"
                      :options="listasForms.sellos"
                      class="form-control form-control-sm p-0"
                      @input="buscarSellos()"
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                      multiple
                    ></v-select>
                    <div class="error" v-if="formEnvio.sellos.length == 0">
                      Seleccione los Sellos
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="gov">GOV</label>
                    <div class="input-group">
                      <input
                        type="number"
                        v-model="formEnvio.gov"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        @blur="validarVolumenes"
                        step="any"
                        :class="
                          $v.formEnvio.gov.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoGuia !== 6"
                        id="gov"
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="gsv">GSV</label>
                    <div class="input">
                      <input
                        type="number"
                        v-model="formEnvio.gsv"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        @blur="validarVolumenes"
                        step="any"
                        :class="
                          $v.formEnvio.gsv.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoGuia !== 6"
                      />
                    </div>
                    <div class="error">
                      <b>Maximos barriles: {{ max_barriles }}</b>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="nsv">NSV</label>
                    <input
                      type="number"
                      v-model="formEnvio.nsv"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      @blur="validarVolumenes"
                      step="any"
                      :class="
                        $v.formEnvio.nsv.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="observaciones">OBSERVACIONES</label>
                    <textarea
                      v-model="formEnvio.observaciones"
                      cols="30"
                      rows="4"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.observaciones.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    ></textarea>
                    <div
                      style="font-size: 13px"
                      class="error"
                      v-if="!$v.formEnvio.observaciones.required"
                    >
                      Ingrese una Observación
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row justify-content-center" style="font-size: 12px">
                  <div class="form-group col-md-3">
                    <label for="temp">ANALISIS DE LAB (TEMP°)</label>
                    <input
                      type="number"
                      v-model="formEnvio.analisis_laboratorio"
                      style="font-size: 13px"
                      min="50"
                      max="250"
                      class="form-control form-control-sm"
                      @blur="validarTemp"
                      :class="
                        $v.formEnvio.analisis_laboratorio.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>A.P.I</label>
                    <input
                      type="number"
                      v-model="formEnvio.api"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      @blur="validarApi"
                      :class="
                        $v.formEnvio.api.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="bsw">B.S.W</label>
                    <input
                      type="number"
                      v-model="formEnvio.bsw"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      @blur="validarBsw"
                      :class="
                        $v.formEnvio.bsw.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="azufre">S%</label>
                    <input
                      type="number"
                      v-model="formEnvio.azufre"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      @blur="validarAzufre"
                      :class="
                        $v.formEnvio.azufre.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Presión <small>(PSI)</small> </label>
                    <input
                      type="text"
                      v-model="formEnvio.presion"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Producto</label>
                    <input
                      type="text"
                      v-model="formEnvio.nProducto"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>C.T.L</label>
                    <input
                      type="text"
                      v-model="formEnvio.ctl"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>% Sedimento</label>
                    <input
                      type="text"
                      v-model="formEnvio.porc_sedimento"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>C.S.W</label>
                    <input
                      type="text"
                      v-model="formEnvio.csw"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>R.V.P</label>
                    <input
                      type="text"
                      v-model="formEnvio.rvp"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Incer. Expandida %</label>
                    <input
                      type="text"
                      v-model="formEnvio.iincer_exp_porc"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Incer. Expandida <small>(bls)</small></label>
                    <input
                      type="text"
                      v-model="formEnvio.iincer_exp_bls"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                </div>
                <!-- <div class="row justify-content-center">
                  <div class="form-group col-md-5">
                    <label>Llamado Cargue</label>
                    <div class="row">
                      <div class="col-md-7">
                        <input
                          v-model="formEnvio.fecha_llamado"
                          type="date"
                          class="form-control form-control-sm"
                          style="font-size: 13px"
                          @input="validaFechasLlamadoCargue()"
                          :disabled="
                            (accion == 'Show' && estadoGuia !== 6) ||
                              !form.liquidacion_guia
                          "
                        />
                      </div>
                      <div class="col-md-5">
                        <input
                          v-model="formEnvio.hora_llamado"
                          type="text"
                          class="form-control form-control-sm"
                          style="font-size: 13px"
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          @input="validaFechasLlamadoCargue()"
                          :disabled="
                            (accion == 'Show' && estadoGuia !== 6) ||
                              !form.liquidacion_guia
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-5">
                    <label>Inicio Cargue</label>
                    <div class="row">
                      <div class="col-md-7">
                        <input
                          v-model="formEnvio.fecha_inicio_cargue"
                          type="date"
                          class="form-control form-control-sm"
                          style="font-size: 13px"
                          @input="validaFechasLlamadoCargue()"
                          :disabled="
                            (accion == 'Show' && estadoGuia !== 6) ||
                              !form.liquidacion_guia
                          "
                        />
                      </div>
                      <div class="col-md-5">
                        <input
                          v-model="formEnvio.hora_inicio_cargue"
                          type="text"
                          class="form-control form-control-sm"
                          style="font-size: 13px"
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          @input="validaFechasLlamadoCargue()"
                          :disabled="
                            (accion == 'Show' && estadoGuia !== 6) ||
                              !form.liquidacion_guia
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="form-group col-md-12">
                    <label for="novedades">NOVEDADES - PROCESO STAND BY</label>
                    <v-select
                      :class="[
                        $v.formEnvio.novedades.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="formEnvio.novedades"
                      placeholder="Novedades"
                      label="descripcion"
                      :options="listasForms.novedades"
                      class="form-control form-control-sm p-0"
                      :disabled="accion == 'Show' && estadoGuia !== 6"
                      multiple
                    ></v-select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="novedades">VENTANA DE DESCARGUE</label>
                    <v-select
                      :class="[
                        $v.formEnvio.ventana_descargue_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="ventanaDescargue"
                      placeholder="Ventana descargue"
                      label="nombre"
                      :options="listasForms.ventana_descargues"
                      @search="buscarVentanasEnturneDescargue"
                      @input="selectVentanaDescargue()"
                      class="form-control form-control-sm p-0"
                      :disabled="!formEnvio.sitio_destino_id"
                    ></v-select>
                    <div class="error" v-if="!formEnvio.ventana_descargue_id">
                      Seleccione ventana de descargue
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="novedades">CATEGORIA DE OPERACIONES</label>
                    <select
                      v-model="formEnvio.categoria_operaciones_id"
                      placeholder="Categoria operaciones"
                      @change="validacionCategoria()"
                      class="form-control form-control-sm p-0 pl-2"
                      :class="[
                        $v.formEnvio.categoria_operaciones_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                    >
                      <option value>Seleccione...</option>
                      <option
                        v-for="cat_operaciones in listasForms.categoria_operaciones"
                        :key="cat_operaciones.numeracion"
                        :value="cat_operaciones.numeracion"
                      >
                        {{ cat_operaciones.descripcion }}
                      </option>
                    </select>
                    <div
                      class="error"
                      v-if="!formEnvio.categoria_operaciones_id"
                    >
                      Seleccione la categoria de la operación
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-2">
                  <button class="btn bg-gray-dark col-md-11" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    class="btn bg-navy col-md-11"
                    v-show="!$v.formEnvio.$invalid"
                    @click="saveDetGuia()"
                    :disabled="accion == 'Show' && estadoGuia !== 6"
                  >
                    <i class="fas fa-paper-plane"></i>
                    <br />Guardar
                  </button>
                </div>
                <div class="col-md-2">
                  <a
                    id="botonImp"
                    type="button"
                    class="btn bg-cyan col-md-11"
                    @click="print()"
                    v-show="
                      accion == 'Show' && (estadoGuia == 6 || estadoGuia == 2)
                    "
                  >
                    <i class="fas fa-print"></i>
                    <br />Imprimir Guía
                  </a>
                </div>
                <!-- <div class="col-md-2">
                  <a
                    type="button"
                    class="btn bg-cyan col-md-11"
                    @click="printBono(form.id)"
                    v-show="
                      form.bono &&
                        accion == 'Show' &&
                        (estadoGuia == 6 || estadoGuia == 2)
                    "
                  >
                    <i class="fas fa-print"></i>
                    <br />Imprimir Bono
                  </a>
                </div> -->
                <div
                  class="col-md-2"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.printActa'
                    )
                  "
                >
                  <a
                    type="button"
                    class="btn bg-primary col-md-11"
                    @click="printActa(form.id)"
                    v-show="
                      form.bono &&
                        accion == 'Show' &&
                        (estadoGuia == 6 || estadoGuia == 2)
                    "
                  >
                    <i class="fas fa-print"></i>
                    <br />Imprimir Acta
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  helpers,
  numeric,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import moment from "moment";
import Loading from "../../../../components/Loading";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
import $ from "jquery";

export default {
  name: "DetGuiasForm",
  components: {
    vSelect,
    Loading,
  },

  data() {
    return {
      cargando: false,
      det_guia: null,
      form: {
        ciudadDestino: null,
        ciudadOrigen: null,
        despachado_a_id: null,
        numeroGuia: null,
        sitio_id: null,
      },
      formEnvio: {
        id: null,
        tipo_producto_id: null,
        ciudad_origen_id: null,
        fecha_expedicion: null,
        fecha_expedicion1: null,
        hora_expedicion: null,
        remision: null,
        despachado_a_id: null,
        conductor_id: null,
        vehiculo_id: null,
        tipo_vehiculo_id: null,
        remolque_id: null,
        transportadora_id: null,
        producto_liquido_id: null,
        ciudad_destino_id: null,
        codigo_destino: null,
        direccion_destino: null,
        sitio_origen_id: null,
        tanque_id: null,
        sitio_destino_id: null,
        ventana_descargue_id: null,
        categoria_operaciones_id: null,
        guia_id: null,
        hora_cargue: null,
        horas_vigencia: null,
        hora_adicional: null,
        novedades: [],
        sellos: [],
        api: null,
        analisis_laboratorio: null,
        presion: null,
        producto: null,
        nProducto: null,
        gov: null,
        gsv: null,
        nsv: null,
        bsw: null,
        azufre: null,
        ctl: null,
        porc_sedimento: null,
        csw: null,
        rvp: null,
        iincer_exp_porc: null,
        iincer_exp_bls: null,
        fecha_llamado: null,
        hora_llamado: null,
        fecha_hora_llamado: null,
        fecha_inicio_cargue: null,
        hora_inicio_cargue: null,
        fecha_hora_inicio_cargue: null,
        numeroGuia: null,
        digito_verificacion: null,
        user_id: null,
        motivo_anulacion: null,
        ruta_a_conocer: null,
        concepto: null,
        cant_cambio_ruta: null,
        ruta_a_conocer_id: null,
        tramo: null,
      },
      cambio_destino: false,
      guia: [],
      volver_viaje: 0,
      det_guias: [],
      estadoGuia: null,
      sitioOrigen: [],
      sitioDestino: [],
      vehiculo: [],
      conductor: [],
      remolque: [],
      ruta: [],
      sitio_type: null,
      sitio_id: null,
      tipo_control: null,
      transportadora: [],
      productoLiquido: [],
      accion: null,
      empresa: null,
      horaCargue: null,
      fechaCargue: null,
      actoGuia: null,
      hasGuias: false,
      rutaA: {},
      listasForms: {
        despachado_a: [],
        sitiosOrigenes: [],
        tanques: [],
        sitiosDestinos: [],
        novedades: [],
        sellos: [],
        guias: [],
        empresas: [],
        tipos_productos: [],
        horasAdicionales: [],
        tipo_producto: [],
        producto_liquido: [],
        ventana_descargues: [],
        categoria_operaciones: [],
        rutasA: [],
      },
      ventanaDescargue: [],
      categoriaOperaciones: [],
      digito_verificacion: null,
      estadoFecha: null,
      modalCalc: false,
      tercero: 0,
      terceroSellos: 0,
      max_barriles: 0,
      acto: "",
    };
  },

  validations() {
    let dv = {};
    let remolque = {};
    let tanque = {};
    let motivo = {};
    let concepto = {};
    let ruta_a_conocer = {};
    let tramo = {};

    if (this.digito_verificacion == null) {
      dv = {
        required,
        minLength: minLength(1),
        maxLength: maxLength(1),
        numeric,
      };
    } else {
      dv = {
        required: false,
      };
    }
    if (this.acto == 3) {
      if (this.formEnvio.tipo_vehiculo_id == 1) {
        remolque = {
          required,
        };
      } else {
        remolque = {
          required: false,
        };
      }
      motivo = {
        required,
        minLength: minLength(10),
        maxLength: maxLength(199),
      };
      if (this.cambio_destino) {
        ruta_a_conocer = {
          required,
        };
        concepto = {
          required,
        };
        tramo = {
          required,
          minValue: minValue(1),
          maxValue: maxValue(2),
        };
      } else {
        ruta_a_conocer = {
          required: false,
        };
        concepto = {
          required: false,
        };
        tramo = {
          required: false,
        };
      }
    } else {
      remolque = {
        required: false,
      };
      motivo = {
        required: false,
      };
      ruta_a_conocer = {
        required: false,
      };
      concepto = {
        required: false,
      };
      tramo = {
        required: false,
      };
    }
    if (
      this.sitioOrigen &&
      this.sitioOrigen.check_tanque &&
      this.formEnvio.tanque_id
    ) {
      tanque = {
        required,
      };
    } else {
      tanque = {
        required: false,
      };
    }

    return {
      formEnvio: {
        digito_verificacion: dv,
        fecha_expedicion1: {
          required,
        },
        hora_expedicion: {
          required,
          timeFormat,
        },
        tipo_producto_id: {
          required,
        },
        sitio_origen_id: {
          required,
        },
        tanque_id: tanque,
        sitio_destino_id: {
          required,
        },
        producto_liquido_id: {
          required,
        },
        guia_id: {
          required,
        },
        novedades: {
          required,
        },
        remision: {
          required,
        },
        sellos: {
          required,
        },
        fecha_cargue: {
          required,
        },
        hora_cargue: {
          required,
          timeFormat,
        },
        analisis_laboratorio: {
          required,
        },
        gov: {
          required,
        },
        gsv: {
          required,
        },
        nsv: {
          required,
        },
        api: {
          required,
        },
        bsw: {
          required,
        },
        azufre: {
          required,
        },
        observaciones: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(199),
        },
        ventana_descargue_id: {
          required,
        },
        vehiculo_id: {
          required,
        },
        remolque_id: remolque,
        conductor_id: {
          required,
        },
        despachado_a_id: {
          required,
        },
        categoria_operaciones_id: {
          required,
        },
        motivo_anulacion: motivo,
        ruta_a_conocer_id: ruta_a_conocer,
        concepto: concepto,
        tramo: tramo,
      },
    };
  },

  methods: {
    async init() {
      this.accion = this.$route.params.accion;
      this.sitio_type = this.$route.params.sitio_type;
      this.sitio_id = this.$route.params.sitio_id;
      this.tipo_control = this.$route.params.tipo_control;
      if (this.$route.params.volver_viaje !== "undefined") {
        this.volver_viaje = 1;
      }

      if (this.$route.params.accion == "Diligenciar") {
        this.form = this.$route.params.data_edit;
        this.acto = 1;
        this.$swal({
          text:
            "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
          icon: "info",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.tipo_producto_id = this.form.tipo_producto_id;
        this.formEnvio.user_id = this.$store.getters.getUser.id;
        await this.getTipoProductos();
        await this.getDespachado();
        await this.getProductosLiquidos();
        await this.getNovedades();
        await this.getHorasAdicionales();
        await this.buscarGuiaInput();
        await this.buscarSellos();
        await this.getCategoriasOperaciones();
        if (this.form.liquidacion_guia && this.form.liquidacion_guia.sellos) {
          let sellosIds = this.form.liquidacion_guia.sellos.split(",");
          this.formEnvio.sellos = sellosIds
            ? sellosIds.map((id) =>
                this.listasForms.sellos.find((item) => item.id === parseInt(id))
              )
            : {};
        }
        this.sitioOrigen = this.form.sitio_cargue;
        this.sitioOrigen.nombre_sigla = this.sitioOrigen.sigla
          ? `${this.sitioOrigen.nombre} - (${this.sitioOrigen.sigla})`
          : this.sitioOrigen.nombre;
        // Se buscan los tanques del sitio
        this.listasForms.tanques = this.sitioOrigen.tanques;
        this.formEnvio.sitio_origen_id = this.form.sitio_cargue.id;
        this.max_barriles = this.$route.params.data_edit.volumen_calculado;
        this.conductor = {
          id: this.form.conductor.id,
          nombre:
            this.form.conductor.apellidos + " " + this.form.conductor.nombres,
          numero_documento: this.form.conductor.numero_documento,
        };
        this.vehiculo = {
          id: this.form.vehiculo.id,
          placa: this.form.vehiculo.placa,
          tipo_vehiculo_id: this.form.vehiculo.tipo_vehiculo_id,
        };
        if (this.form.remolque !== null) {
          this.remolque = {
            id: this.form.remolque.id,
            placa: this.form.remolque.placa,
          };
          this.formEnvio.remolque_id = this.remolque.id;
        }
        this.transportadora = {
          id: this.form.transportadora.id,
          razon_social: this.form.transportadora.razon_social,
        };

        this.formEnvio.fecha_cargue = moment().format("YYYY-MM-DD");
        this.formEnvio.hora_cargue = moment().format("HH:mm:ss");
        this.form.ciudadOrigen = this.form.sitio_cargue.ciudad.nombre;
        this.formEnvio.ciudad_origen_id = this.form.sitio_cargue.ciudad.id;
        this.formEnvio.tanque_id = this.$route.params.data_edit.tanque_id;
        this.formEnvio.producto_liquido_id = this.formEnvio.remision = this.form.orden_de_cargue;
        this.formEnvio.conductor_id = this.conductor.id;
        this.formEnvio.cedula_conductor = this.conductor.numero_documento;
        this.formEnvio.vehiculo_id = this.vehiculo.id;
        this.formEnvio.tipo_vehiculo_id = this.vehiculo.tipo_vehiculo_id;

        this.formEnvio.transportadora_id = this.transportadora.id;
        this.formEnvio.producto_liquido_id = this.productoLiquido.id;
      } else if (this.$route.params.accion == "Show") {
        this.formEnvio = this.$route.params.data_edit;
        this.acto = 2;
        this.estadoGuia = this.formEnvio.guia
          ? this.formEnvio.guia.estado
          : null;
        this.form.id = this.form.id
          ? this.form.id
          : this.$route.params.viaje
          ? this.$route.params.viaje.id
          : null;
        this.formEnvio.tanque_id = this.formEnvio.tanque_id
          ? this.formEnvio.tanque_id
          : this.$route.params.viaje
          ? this.$route.params.viaje.tanque_id
          : null;

        this.form.bono = this.formEnvio.bono
          ? this.formEnvio.bono
          : this.$route.params.viaje.bono;
        this.ruta = this.formEnvio.ruta;
        await this.getHorasAdicionales();
        await this.getTipoProductos();
        await this.getNovedades();
        await this.getCategoriasOperaciones();
        //await this.buscarGuiaInput();
        this.conductor = {
          id: this.formEnvio.conductor.id,
          nombre:
            this.formEnvio.conductor.apellidos +
            " " +
            this.formEnvio.conductor.nombres,
          numero_documento: this.formEnvio.conductor.numero_documento,
        };
        this.vehiculo = {
          id: this.formEnvio.vehiculo.id,
          placa: this.formEnvio.vehiculo.placa,
        };
        if (this.formEnvio.remolque !== null) {
          this.remolque = {
            id: this.formEnvio.remolque.id,
            placa: this.formEnvio.remolque.placa,
          };
        }
        this.transportadora = {
          id: this.formEnvio.transportadora.id,
          razon_social: this.formEnvio.transportadora.razon_social,
        };
        this.sitioDestino = {
          id: this.formEnvio.sitio_destino.id,
          nombre: this.formEnvio.sitio_destino.nombre,
          nombre_sigla: this.formEnvio.sitio_destino.sigla
            ? `${this.formEnvio.sitio_destino.nombre} - (${this.formEnvio.sitio_destino.sigla})`
            : this.formEnvio.sitio_destino.nombre,
          codigo: this.formEnvio.sitio_destino.codigo,
        };
        this.sitioOrigen = {
          id: this.formEnvio.sitio_origen.id,
          nombre: this.formEnvio.sitio_origen.nombre,
          nombre_sigla: this.formEnvio.sitio_origen.sigla
            ? `${this.formEnvio.sitio_origen.nombre} - (${this.formEnvio.sitio_origen.sigla})`
            : this.formEnvio.sitio_origen.nombre,
          codigo: this.formEnvio.sitio_origen.codigo,
          check_tanque: parseInt(this.formEnvio.sitio_origen.check_tanque),
          tanques: this.formEnvio.sitio_origen.tanques,
        };
        this.listasForms.producto_liquido[0] = {
          id: this.formEnvio.producto_liquido.id,
          nombre: this.formEnvio.producto_liquido.nombre,
          codigo: this.formEnvio.producto_liquido.codigo,
        };
        this.listasForms.despachado_a[0] = {
          numeracion: this.formEnvio.ruta.despachado_a_id,
          descripcion: this.formEnvio.ruta.despachadoA,
        };
        this.form.despachado_a_id = this.formEnvio.despachado_a_id;
        this.form.numeroGuia =
          this.formEnvio.guia.numero +
          "-" +
          this.formEnvio.guia.digito_verificacion;
        this.formEnvio.horas_vigencia =
          this.formEnvio.horas_vigencia + " Horas";
        this.form.ciudadOrigen = this.formEnvio.ciudad_origen.nombre;
        this.form.ciudadDestino = this.formEnvio.ciudad_destino.nombre;
        let fecha = this.formEnvio.fecha_expedicion.slice(0, 10);
        let hora = this.formEnvio.fecha_expedicion.slice(11, 19);
        this.formEnvio.fecha_expedicion1 = fecha;
        this.formEnvio.hora_expedicion = hora;
        this.ventanaDescargue = this.formEnvio.ventana_enturnamiento_descargue.nombre;
        if (this.ruta.rutas_despachados.length > 0) {
          this.listasForms.despachado_a = this.ruta.rutas_despachados;
        } else {
          this.getDespachado();
        }
        this.cargando = false;
      } else if (this.$route.params.accion == "Cambiar") {
        let viaje = this.$route.params.data_edit;
        let guia = this.$route.params.data_edit.guia;
        this.acto = 3;
        this.formEnvio = guia.det_guias[0];
        let fecha = this.formEnvio.fecha_expedicion.slice(0, 10);
        let hora = this.formEnvio.fecha_expedicion.slice(11, 19);
        this.formEnvio.fecha_expedicion1 = fecha;
        this.formEnvio.hora_expedicion = hora;
        this.form.sitio_cargue_id = viaje.ruta.origen.id;
        this.form.ciudadOrigen = viaje.ruta.origen.ciudad.nombre;
        this.form.ciudadDestino = viaje.ruta.destino.ciudad.nombre;
        this.form.despachado_a_id = viaje.ruta.despachado_a_id;
        this.max_barriles = viaje.volumen_calculado;
        this.ruta = this.formEnvio.ruta;
        if (this.ruta.rutas_despachados.length > 0) {
          this.listasForms.despachado_a = this.ruta.rutas_despachados;
        } else {
          this.getDespachado();
        }

        this.form.id = viaje.id;
        this.$swal({
          text:
            "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
          icon: "info",
          confirmButtonText: "Aceptar!",
        });
        await this.getTipoProductos();
        await this.getProductosLiquidos();
        await this.getNovedades();
        await this.getHorasAdicionales();
        await this.buscarGuiaInput();
        await this.buscarSellos();
        await this.getCategoriasOperaciones();
        await this.getRuta();
        this.sitioDestino = viaje.ruta.destino;
        this.sitioDestino.nombre_sigla = this.sitioDestino.sigla
          ? `${this.sitioDestino.nombre} - (${this.sitioDestino.sigla})`
          : this.sitioDestino.nombre;
        this.sitioOrigen = viaje.ruta.origen;
        this.sitioOrigen.tanques = viaje.sitio_cargue.tanques;
        this.sitioOrigen.nombre_sigla = this.sitioOrigen.sigla
          ? `${this.sitioOrigen.nombre} - (${this.sitioOrigen.sigla})`
          : this.sitioOrigen.nombre;
        this.sitioOrigen.check_tanque = parseInt(
          viaje.sitio_cargue.check_tanque
        );
        // Se buscan los tanques del sitio
        this.listasForms.tanques = this.sitioOrigen.tanques;
        this.formEnvio.tanque_id = this.$route.params.data_edit.tanque_id;
        this.conductor = viaje.conductor;
        this.conductor.nombre = `${viaje.conductor.nombres} ${viaje.conductor.apellidos}`;
        this.vehiculo = viaje.vehiculo;
        this.formEnvio.tipo_vehiculo_id = this.vehiculo.tipo_vehiculo_id;
        if (viaje.remolque !== null) {
          this.remolque = viaje.remolque;
        }
        this.transportadora = viaje.transportadora;
        this.form.despachado_a_id = viaje.ruta.despachado_a_id;
        this.ventanaDescargue = this.formEnvio.ventana_enturnamiento_descargue.nombre;
        this.formEnvio.tipo_act = 3;
      } else if (this.$route.params.accion == "Actualizar") {
        let viaje = this.$route.params.data_edit;
        let guia = this.$route.params.data_edit.guia;
        this.formEnvio = guia.det_guias[0];
        this.acto = 2;
        let hora = this.formEnvio.fecha_expedicion.slice(11, 19);
        this.formEnvio.hora_expedicion = hora;
        let fecha = this.formEnvio.fecha_expedicion.slice(0, 10);
        this.formEnvio.fecha_expedicion1 = fecha;
        this.form.sitio_cargue_id = viaje.ruta.origen.id;
        this.form.ciudadOrigen = viaje.ruta.origen.ciudad.nombre;
        this.form.ciudadDestino = viaje.ruta.destino.ciudad.nombre;
        this.form.despachado_a_id = viaje.ruta.despachado_a_id;
        this.form.numeroGuia = guia.numero + "-" + guia.digito_verificacion;
        this.formEnvio.numeroGuia = guia.numero + guia.digito_verificacion;
        this.max_barriles = viaje.volumen_calculado;
        this.ruta = this.formEnvio.ruta;
        if (this.ruta.rutas_despachados.length > 0) {
          this.listasForms.despachado_a = this.ruta.rutas_despachados;
        } else {
          this.getDespachado();
        }
        this.formEnvio.tipo_act = 4;
        this.form.id = viaje.id;
        this.formEnvio.tipo_vehiculo_id = viaje.tipo_vehiculo_id;
        this.$swal({
          text:
            "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
          icon: "info",
          confirmButtonText: "Aceptar!",
        });
        await this.getTipoProductos();
        await this.getProductosLiquidos();
        await this.getNovedades();
        await this.getHorasAdicionales();
        await this.buscarSellos();
        await this.buscarDVActGuia();
        await this.getCategoriasOperaciones();
        this.sitioDestino = viaje.ruta.destino;
        this.sitioDestino.nombre_sigla = this.sitioDestino.sigla
          ? `${this.sitioDestino.nombre} - (${this.sitioDestino.sigla})`
          : this.sitioDestino.nombre;
        this.sitioOrigen = viaje.ruta.origen;
        this.sitioOrigen.tanques = viaje.sitio_cargue.tanques;
        this.sitioOrigen.nombre_sigla = this.sitioOrigen.sigla
          ? `${this.sitioOrigen.nombre} - (${this.sitioOrigen.sigla})`
          : this.sitioOrigen.nombre;
        this.sitioOrigen.check_tanque = parseInt(
          viaje.sitio_cargue.check_tanque
        );
        // Se buscan los tanques del sitio
        this.listasForms.tanques = this.sitioOrigen.tanques;
        this.formEnvio.tanque_id = this.$route.params.data_edit.tanque_id;
        this.conductor = viaje.conductor;
        this.conductor.nombre = `${viaje.conductor.nombres} ${viaje.conductor.apellidos}`;
        this.vehiculo = viaje.vehiculo;
        if (viaje.remolque !== null) {
          this.remolque = viaje.remolque;
        }
        this.transportadora = viaje.transportadora;
        this.form.despachado_a_id = viaje.ruta.despachado_a_id;
        this.ventanaDescargue = this.formEnvio.ventana_enturnamiento_descargue.nombre;
      } else {
        this.cargando = false;
        this.id = this.$route.params.id;
      }

      // Se llenan los datos relacionados con la liquidacion de la guia
      if (
        this.$route.params &&
        this.$route.params.data_edit &&
        this.$route.params.data_edit.liquidacion_guia
      ) {
        const liq = this.$route.params.data_edit.liquidacion_guia;
        this.max_barriles = this.$route.params.data_edit.volumen_calculado;
        this.formEnvio.gov = liq.gov;
        this.formEnvio.gsv = liq.gsv;
        this.formEnvio.nsv = liq.nsv;
        this.formEnvio.analisis_laboratorio = liq.temperatura;
        this.formEnvio.api = liq.api;
        this.formEnvio.bsw = liq.bsw;
        this.formEnvio.azufre = liq.azufre;

        this.formEnvio.presion = liq.presion;
        this.formEnvio.producto = liq.producto;
        this.formEnvio.nProducto = liq.nProducto;
        this.formEnvio.ctl = liq.ctl;
        this.formEnvio.porc_sedimento = liq.porc_sedimento;
        this.formEnvio.csw = liq.csw;
        this.formEnvio.rvp = liq.rvp;
        this.formEnvio.iincer_exp_porc = liq.iincer_exp_porc;
        this.formEnvio.iincer_exp_bls = liq.iincer_exp_bls;
        this.formEnvio.tanque_id = liq.tanque_id;
        this.formEnvio.producto_liquido_id = liq.producto_liquido_id;

        await this.validarTemp();
        await this.validarBsw();
        await this.validarAzufre();
        await this.validarApi();
      } else {
        this.formEnvio.presion = "Sin Liquidacion";
        this.formEnvio.nProducto = "Sin Liquidacion";
        this.formEnvio.ctl = "Sin Liquidacion";
        this.formEnvio.porc_sedimento = "Sin Liquidacion";
        this.formEnvio.csw = "Sin Liquidacion";
        this.formEnvio.rvp = "Sin Liquidacion";
        this.formEnvio.iincer_exp_porc = "Sin Liquidacion";
        this.formEnvio.iincer_exp_bls = "Sin Liquidacion";
      }

      // Se llenan los datos relacionados con las fechas de llamado e inicio del cargue
      if (
        this.$route.params &&
        this.$route.params.data_edit &&
        this.$route.params.data_edit.trazas_fechas_viaje
      ) {
        const tfv = this.$route.params.data_edit.trazas_fechas_viaje;
        if (tfv.fecha_hora_llamado) {
          this.formEnvio.fecha_hora_llamado = tfv.fecha_hora_llamado;
          const llamado = moment(this.formEnvio.fecha_hora_llamado);
          this.formEnvio.fecha_llamado = llamado.format("YYYY-MM-DD");
          this.formEnvio.hora_llamado = llamado.format("HH:mm:ss");
        }

        if (tfv.fecha_hora_inicio_cargue) {
          this.formEnvio.fecha_hora_inicio_cargue =
            tfv.fecha_hora_inicio_cargue;
          const inicio_cargue = moment(this.formEnvio.fecha_hora_inicio_cargue);
          this.formEnvio.fecha_inicio_cargue = inicio_cargue.format(
            "YYYY-MM-DD"
          );
          this.formEnvio.hora_inicio_cargue = inicio_cargue.format("HH:mm:ss");
        }
      }
    },

    validarTemp() {
      if (
        this.formEnvio.analisis_laboratorio > 250 ||
        this.formEnvio.analisis_laboratorio < 50
      ) {
        this.$swal({
          text:
            "La temperatura debe estar entre 50°C y 250°C. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.analisis_laboratorio = null;
      }
    },

    validarApi() {
      if (this.formEnvio.api > 95 || this.formEnvio.api < 6) {
        this.$swal({
          text:
            "El A.P.I debe estar entre 6 y 95. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.api = null;
      }
    },

    async validarBsw() {
      if (this.formEnvio.bsw > 20 || this.formEnvio.bsw < 0) {
        this.$swal({
          text:
            "El valor del porcentaje de B.S.W debe estar entre 0 y 20. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.bsw = null;
      }
      await this.validarVolumenes();
    },

    validarAzufre() {
      if (this.formEnvio.azufre > 100 || this.formEnvio.azufre < 0) {
        this.$swal({
          text:
            "El valor del porcentaje de Azufre debe estar entre 0 y 100. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.azufre = null;
      }
    },

    validarVolumenes() {
      if (this.formEnvio.gsv != null) {
        if (this.formEnvio.gov > 300 || this.formEnvio.gov < 0) {
          this.$swal({
            text:
              "El valor del GOV no puede ser mayor a 300. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.gov = null;
        }
        if (this.formEnvio.gsv > this.formEnvio.gov) {
          this.$swal({
            text:
              "El valor del GOV no puede ser menor al GSV. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.gov = null;
          this.formEnvio.gsv = null;
        }
        if (this.formEnvio.gsv > this.max_barriles) {
          this.$swal({
            text:
              "El valor de GSV no puede superar " +
              this.max_barriles +
              " barriles",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.gsv = null;
        }
      }

      if (this.formEnvio.gsv != null && this.formEnvio.gov != null) {
        if (this.formEnvio.gsv >= this.formEnvio.gov) {
          this.$swal({
            text:
              "El valor de GSV no puede ser mayor o igual al valor de GOV. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.gsv = null;
          this.formEnvio.gov = null;
        }
      }

      if (this.formEnvio.gsv != null && this.formEnvio.nsv != null) {
        if (this.formEnvio.nsv > this.formEnvio.gsv) {
          this.$swal({
            text:
              "El valor de NSV no puede ser mayor al valor de GSV. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.nsv = null;
          this.formEnvio.gsv = null;
        }
      }

      if (
        this.formEnvio.gsv != null &&
        this.formEnvio.nsv != null &&
        this.formEnvio.bsw != null
      ) {
        //Variables para el calculo GSV
        let bswResult = 1 - this.formEnvio.bsw / 100;
        let gsv = this.formEnvio.nsv / bswResult;
        let gsvResult = gsv - this.formEnvio.gsv;

        //Variables para el calculo NSV
        let nsv = this.formEnvio.gsv * bswResult;
        let nsvResult = nsv - this.formEnvio.nsv;

        if (Math.abs(gsvResult) > 0.05) {
          this.$swal({
            text:
              "El valor del GSV calculado " +
              gsv.toFixed(2) +
              " no cumple con el GSV escrito, Favor validar la información.!!",
            icon: "error",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.gsv = null;
          this.formEnvio.bsw = null;
        }

        if (Math.abs(nsvResult) > 0.05) {
          this.$swal({
            text:
              "El valor del NSV calculado " +
              nsv.toFixed(2) +
              " no cumple con el NSV escrito, Favor validar la información.!!",
            icon: "error",
            confirmButtonText: "Aceptar!",
          });
          this.formEnvio.nsv = null;
          this.formEnvio.bsw = null;
        }
      }
    },

    getTipoProductos() {
      axios.get("/api/admin/productos/lista").then((response) => {
        this.cargando = false;
        this.listasForms.tipo_producto = response.data;
      });
    },

    getProductosLiquidos() {
      axios
        .get(
          "/api/hidrocarburos/detalleGuias/listaProductoLiqui?sitio_id=" +
            this.form.sitio_cargue_id +
            "&sitio_type=" +
            this.$route.params.sitio_type
        )
        .then((response) => {
          this.listasForms.producto_liquido =
            response.data[0].productos_liquidos;
        });
    },

    checkFecha() {
      this.estadoFecha = null;
      if (
        this.formEnvio.fecha_expedicion.slice(0, 10) >
        this.formEnvio.fecha_cargue
      ) {
        this.$swal({
          title: "Revisar Fechas!!",
          text:
            "La fecha de cargue no puede ser menor a la fecha de expedición",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.estadoFecha = 1;
      } else {
        if (
          this.formEnvio.fecha_expedicion.slice(11, 19) >
          this.formEnvio.hora_cargue
        ) {
          this.$swal({
            title: "Revisar Horas!!",
            text:
              "La hora de cargue no puede ser menor a la hora de expedición",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.estadoFecha = 2;
        }
      }
    },

    // funcion que valida fecha de expedicion y fecha final
    validaFechas(acc) {
      if (
        this.formEnvio.fecha_expedicion1 &&
        this.formEnvio.fecha_cargue &&
        this.formEnvio.hora_expedicion &&
        this.formEnvio.hora_cargue &&
        this.form.trazas_fechas_viaje.fecha_enturne_cargue
      ) {
        var fecha_menor = new Date(
          this.formEnvio.fecha_expedicion1 +
            " " +
            this.formEnvio.hora_expedicion
        );
        var fecha_mayor = new Date(
          this.formEnvio.fecha_cargue + " " + this.formEnvio.hora_cargue
        );

        var fecha_enturne_cargue = new Date(
          this.form.trazas_fechas_viaje.fecha_enturne_cargue
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.$swal({
            icon: "error",
            title: `La fecha y hora de expedicion no puede ser mayor a la fecha y hora de salida...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.formEnvio.fecha_expedicion1 = null;
          this.formEnvio.hora_expedicion = null;
        }

        if (fecha_menor < fecha_enturne_cargue && acc == 1) {
          this.$swal({
            icon: "error",
            title:
              `La fecha y hora de expedicion no puede ser menor a la fecha y hora de enturne a cargue ` +
              this.form.trazas_fechas_viaje.fecha_enturne_cargue +
              `. Por favor validar...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.formEnvio.hora_expedicion = null;
        }

        if (fecha_mayor < fecha_enturne_cargue && acc == 2) {
          this.$swal({
            icon: "error",
            title:
              `La fecha y hora de cargue no puede ser menor a la fecha y hora de enturne a cargue ` +
              this.form.trazas_fechas_viaje.fecha_enturne_cargue +
              `. Por favor validar...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          this.formEnvio.hora_cargue = null;
        }
      }
    },

    validaFechasLlamadoCargue() {
      if (
        this.formEnvio.fecha_llamado &&
        this.formEnvio.hora_llamado &&
        this.formEnvio.fecha_inicio_cargue &&
        this.formEnvio.hora_inicio_cargue
      ) {
        var fecha_menor = new Date(
          this.formEnvio.fecha_llamado + " " + this.formEnvio.hora_llamado
        );
        var fecha_mayor = new Date(
          this.formEnvio.fecha_inicio_cargue +
            " " +
            this.formEnvio.hora_inicio_cargue
        );
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.$swal({
            icon: "error",
            title: `La fecha y hora de llamado de cargue no puede ser mayor a la fecha y hora de inicio del cargue...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formEnvio.fecha_inicio_cargue = null;
          this.formEnvio.hora_inicio_cargue = null;
        }
      }
    },

    //------------------------------------------------------------------------------------
    getNovedades() {
      axios.get("/api/lista/52").then((response) => {
        this.listasForms.novedades = response.data;
      });
    },

    async buscarSellos() {
      let me = this;
      me.cargando = true;
      let url = "api/hidrocarburos/sellos/lista";
      let tipo_empresa = 1;

      if (me.terceroSellos == true) {
        tipo_empresa = 2;
      }
      await axios
        .get(url, {
          params: {
            tipo_empresa,
            viaje_id: me.form.id,
            sitio_id: me.form.sitio_cargue_id,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sellos = respuesta;
          me.formEnvio.sello_id = me.listasForms.sellos.id;
          me.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getHorasAdicionales() {
      axios.get("/api/lista/55").then((response) => {
        this.listasForms.horasAdicionales = response.data;
      });
    },

    getCategoriasOperaciones() {
      axios.get("/api/lista/182").then((response) => {
        response.data.forEach((element) => {
          if (
            element.numeracion == 1 ||
            element.numeracion == 3 ||
            element.numeracion == 5
          ) {
            this.listasForms.categoria_operaciones.push(element);
          }
        });
      });
    },

    async saveDetGuia() {
      var confirm = true;
      if (this.formEnvio.tipo_act !== undefined) {
        if (this.formEnvio.tipo_act == 3) {
          confirm = false;
          await this.$swal({
            title: "Esta seguro de anular y cambiar la guia?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Anular y cambiar!",
          }).then((result) => {
            if (result.value) {
              confirm = true;
            }
          });
        }
      }
      if (confirm) {
        this.formEnvio.viaje_id = this.form.id;
        if (!this.$v.formEnvio.$invalid) {
          if (!this.formEnvio.hora_adicional && this.formEnvio.tipo_act != 4) {
            this.$swal({
              title: "Hora Adicional",
              text: "Esta seguro de que no desea asignar horas adicionales?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Si, Aceptar!",
            }).then((result) => {
              if (result.value) {
                if (this.estadoFecha == 1 || this.estadoFecha == 2) {
                  this.$swal({
                    title: "Revisar Fechas!!",
                    text:
                      "La fecha y hora de cargue no puede ser menor a la fecha y hora de expedición",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Aceptar!",
                  }).then((result) => {
                    if (result.value) {
                      this.save();
                    }
                  });
                } else {
                  this.save();
                }
              }
            });
          } else {
            if (this.estadoFecha == 1 || this.estadoFecha == 2) {
              this.$swal({
                title: "Revisar Fechas!!",
                text:
                  "La fecha y hora de cargue no puede ser menor a la fecha y hora de expedición",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar!",
              }).then((result) => {
                if (result.value) {
                  this.save();
                }
              });
            } else {
              this.save();
            }
          }
        }
      }
    },

    save() {
      let guia = null;
      if (this.formEnvio.tipo_act !== 4) {
        this.estadoGuia = 6;
      }

      this.formEnvio.fecha_expedicion =
        this.formEnvio.fecha_expedicion1 + " " + this.formEnvio.hora_expedicion;
      if (this.form.bono) {
        this.formEnvio.bono = this.form.bono;
      }
      if (this.digito_verificacion != null) {
        guia = this.formEnvio.numeroGuia;
      } else {
        guia = this.formEnvio.numeroGuia + this.formEnvio.digito_verificacion;
      }
      this.formEnvio.numeroGuia = guia;

      this.formEnvio.fecha_hora_llamado =
        this.formEnvio.fecha_llamado && this.formEnvio.hora_llamado
          ? `${this.formEnvio.fecha_llamado} ${this.formEnvio.hora_llamado}`
          : null;

      this.formEnvio.fecha_hora_inicio_cargue =
        this.formEnvio.fecha_inicio_cargue && this.formEnvio.hora_inicio_cargue
          ? `${this.formEnvio.fecha_inicio_cargue} ${this.formEnvio.hora_inicio_cargue}`
          : null;

      axios({
        method: "POST",
        url: "/api/hidrocarburos/detalleGuias",
        data: this.formEnvio,
      })
        .then((response) => {
          let html = ``;
          let detGuia = response.data.detGuia;
          let stock = response.data.stock;
          if (detGuia && detGuia.msg) {
            this.$swal({
              icon: "error",
              title: detGuia.msg,
              html: html,
              position: "top-end",
              showConfirmButton: true,
              confirmButtonText: "OK!",
            });
          } else {
            if (this.formEnvio.tipo_act !== 4) {
              this.$route.params.accion = "Show";
              this.$route.params.data_edit = this.formEnvio;
              this.$route.params.data_edit.conductor = detGuia.conductor;
              this.$route.params.data_edit.vehiculo = detGuia.vehiculo;
              this.$route.params.data_edit.transportadora =
                detGuia.transportadora;
              this.$route.params.data_edit.sitio_origen = detGuia.sitio_origen;
              this.$route.params.data_edit.sitio_destino =
                detGuia.sitio_destino;
              this.$route.params.data_edit.remolque = detGuia.remolque;
              this.$route.params.data_edit.ventana_enturnamiento_descargue =
                detGuia.ventana_enturnamiento_descargue;
              this.$route.params.data_edit.guia = detGuia.guia;
              this.$route.params.data_edit.producto_liquido =
                detGuia.producto_liquido;
              this.$route.params.data_edit.ruta = detGuia.ruta;
              this.$route.params.viaje = detGuia.guia.viaje;
              this.formEnvio.id = detGuia.id;
              if (detGuia.bono) {
                this.formEnvio.bono = detGuia.bono;
                this.form.bono = detGuia.bono;
                html = `
                <h3>Felicitaciones..</h3>
                Esta guía aplica para bono; ahora podrá imprimir el acta para reclamarlo.
              `;
                if (stock) {
                  html =
                    html +
                    `<br> Si hay bonos disponibles en el stock, puede asignarlos en gestión Bonos.`;
                }
              }
              this.init();
            }
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              html: html,
              //position: "top-end",
              showConfirmButton: true,
              confirmButtonText: "OK!",
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    print() {
      $("#botonImp")
        .attr(
          "href",
          process.env.VUE_APP_API_URL + "/toPrint/guia/" + this.formEnvio.id
        )
        .attr("target", "_blank");
      this.estadoGuia = 2;
    },

    printActa(viaje_id) {
      axios
        .get(`/api/hidrocarburos/detalleGuias/printActa`, {
          params: { viaje_id },
        })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    printBono(viaje_id) {
      axios
        .get(`/api/hidrocarburos/bonoGuia/printBono`, {
          params: { viaje_id },
        })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    async checkRuta() {
      let me = this;
      me.cargando = true;
      me.cambio_destino = false;
      var url =
        "api/hidrocarburos/detalleGuias/checkRoute?sitio_origen_id=" +
        this.formEnvio.sitio_origen_id +
        "&sitio_destino_id=" +
        this.sitioDestino.id +
        "&viaje_id=" +
        this.form.id +
        "&producto_liquido_id=" +
        this.formEnvio.producto_liquido_id;

      await axios
        .get(url)
        .then(async function(response) {
          let respuesta = response.data;
          let msg_error = "";
          let codigoSis = null;
          if (respuesta.ruta !== null) {
            let ruta = respuesta.ruta;
            let cargues_programados =
              respuesta.cargues_programados[0].det_postulacion
                .det_solicitud_diaria.solicitud_diaria.det_programacion_sitio;
            let tipo_ruta_viaje = respuesta.cargues_programados[0].tipo_ruta;
            let guias_asignadas = respuesta.cargues_programados[0].guias.length;
            codigoSis = respuesta.codsiscom;

            me.ruta = {
              id: ruta.id,
              horas_vigencia: ruta.horas_vigencia + " Horas",
              despachado_a: ruta.despachadoA,
              despachado_a_id: ruta.despachado_a_id,
              tipo_ruta: ruta.tipo_ruta,
              rutas_despachados: ruta.rutas_despachados,
              nombre: ruta.nombre,
              codigo: ruta.codigo,
            };

            if (ruta.rutas_despachados.length > 0) {
              me.listasForms.despachado_a = ruta.rutas_despachados;
            } else {
              me.getDespachado();
            }

            // Se valida si la ruta seleccionada correponde a la ruta del viaje
            /**
             * Se debe tener en cuenta que se puede pasar de ruta larga a ruta corta,
             * pero no de ruta corta a ruta larga
             */

            // // Si el tipo de ruta del viaje es corta se debe validar que sea igual a la ruta seleccionada
            // if (tipo_ruta_viaje == 1) {
            //   if (tipo_ruta_viaje != ruta.tipo_ruta) {
            //     msg_error +=
            //       "No se puede asignar una ruta larga a un viaje de ruta corta.";
            //   }
            // }

            // Se valida si se puede asignar el destino según el modelo del vehículo
            if (ruta.tipo_ruta == 2) {
              const year = new Date();
              let modelo = parseInt(
                respuesta.cargues_programados[0].vehiculo.modelo
              );
              if (year.getFullYear() - modelo > 15) {
                msg_error += `Vehículo con modelo mayor a 15 años no es permitido.`;
              }
            }
            // Si no hay error se valida el limite de asignación de las guías permitida
            if (!msg_error) {
              let error = "";
              switch (ruta.tipo_ruta) {
                case 1:
                  if (
                    guias_asignadas >= cargues_programados.cargues_ruta_corta
                  ) {
                    error +=
                      "El límite de asignación de guías ya está lleno para las rutas cortas.";
                  }
                  break;

                case 2:
                  if (
                    guias_asignadas >= cargues_programados.cargues_ruta_larga
                  ) {
                    error +=
                      "El límite de asignación de guías ya está lleno para las rutas largas.";
                  }
                  break;

                default:
                  error += "Error en el tipo de ruta.";
                  break;
              }
              if (error) {
                me.cargando = false;
                let erroHtml = `<div>${error}</div>`;
                await me.$swal({
                  html: erroHtml,
                  showCancelButton: false,
                  focusConfirm: true,
                  icon: "info",
                  title: "Aviso guias programadas",
                  showConfirmButton: false,
                  showCloseButton: true,
                });
              }
            }
          } else {
            msg_error +=
              "Ruta no encontrada, por favor seleccione otro destino.";
            me.ruta = {
              id: null,
              horas_vigencia: null,
              despachado_a: null,
              despachado_a_id: null,
            };
          }

          if (!msg_error) {
            me.form.ciudadDestino = me.sitioDestino.ciudad.nombre;
            me.formEnvio.ruta_id = me.ruta.id;
            me.formEnvio.ciudad_destino_id = me.sitioDestino.ciudad.id;
            me.formEnvio.direccion_destino = me.sitioDestino.direccion;
            me.formEnvio.codigo_destino = codigoSis
              ? codigoSis.cod_sicom
              : me.sitioDestino.ciudad.cod_dane;
            me.formEnvio.horas_vigencia = me.ruta.horas_vigencia;
            me.form.despachado_a_id = me.ruta.despachado_a_id;
            me.formEnvio.despachado_a_id = me.ruta.despachado_a_id;
            me.formEnvio.sitio_destino_id = me.sitioDestino.id;
            /* Se crea proceso para el redireccinamiento de Vehículo */
            if (me.acto == 3) {
              if (
                me.formEnvio.sitio_destino.id != me.formEnvio.sitio_destino_id
              ) {
                me.$swal({
                  title: "Este cambio de destino genera costo adicional?.",
                  text:
                    "Por favor validar nuevamente y aceptar si está seguro, de lo contrario por favor cancelar.",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Aceptar!",
                }).then((result) => {
                  if (result.value) {
                    me.cambio_destino = true;
                  } else {
                    me.cambio_destino = false;
                    me.formEnvio.ruta_a_conocer_id = null;
                    me.formEnvio.concepto = null;
                    me.formEnvio.tramo = null;
                    me.rutaA = {};
                  }
                });
              } else {
                me.formEnvio.ruta_a_conocer_id = null;
                me.formEnvio.concepto = null;
                me.formEnvio.tramo = null;
                me.rutaA = {};
              }
            }
          } else {
            me.$swal({
              icon: "error",
              title: msg_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            me.sitioDestino = null;
            me.formEnvio.sitio_destino_id = null;
          }
          me.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error... " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.cargando = false;
        });
    },

    buscarSitioOrigen(search) {
      if (search != "") {
        let me = this;
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.sitiosOrigenes = [];
            response.data.forEach((r) => {
              me.listasForms.sitiosOrigenes.push({
                id: r.id,
                nombre: r.nombre,
                nombre_sigla: r.sigla ? `${r.nombre} - (${r.sigla})` : r.nombre,
                codigo: r.codigo,
                ciudad: r.ciudad,
                check_tanque: r.check_tanque,
                tanques: r.tanques,
              });
            });
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarSitioDestino(search) {
      if (search != "") {
        let me = this;
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.sitiosDestinos = [];
            response.data.forEach((r) => {
              me.listasForms.sitiosDestinos.push({
                id: r.id,
                nombre: r.nombre,
                nombre_sigla: r.sigla ? `${r.nombre} - (${r.sigla})` : r.nombre,
                codigo: r.codigo,
                ciudad: r.ciudad,
                direccion: r.dirreccion,
              });
            });
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarGuiaInput() {
      let me = this;
      me.cargando = true;
      let url = "api/hidrocarburos/guias/lista";
      let tipo_empresa = 1;
      let anulacion = 0;

      if (this.acto == 3) {
        anulacion = 1;
      }

      if (me.tercero == true) {
        tipo_empresa = 2;
      }
      axios
        .get(url, {
          params: {
            sitio_id: me.form.sitio_cargue_id,
            tipo_empresa,
            tipo_producto_id: me.formEnvio.tipo_producto_id,
            anulacion: anulacion,
          },
        })
        .then((response) => {
          let respuesta = response.data;
          me.form.numeroGuia = null;
          if (anulacion) {
            me.listasForms.guias = respuesta;
          } else {
            if (respuesta.numero == undefined) {
              me.$swal({
                text: "No se encontro ninguna guia en el stock",
                icon: "error",
                confirmButtonText: "Aceptar!",
              });
            } else {
              if (respuesta.digito_verificacion != null) {
                me.form.numeroGuia =
                  respuesta.numero + "-" + respuesta.digito_verificacion;
                me.formEnvio.numeroGuia =
                  respuesta.numero + respuesta.digito_verificacion;
                me.digito_verificacion = respuesta.digito_verificacion;
              } else {
                me.form.numeroGuia = respuesta.numero;
                me.formEnvio.numeroGuia = respuesta.numero;
              }
              me.formEnvio.guia_id = respuesta.id;
              me.estadoGuia = respuesta.estado;
            }
          }
          me.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarDVActGuia() {
      let g = this.form.numeroGuia.split("-");
      if (g.length > 0) {
        this.digito_verificacion = g[1];
      } else {
        this.digito_verificacion = null;
      }
    },

    selectGuia() {
      if (this.guia) {
        this.formEnvio.guia_id = this.guia.id;
        this.formEnvio.numeroGuia = this.guia.digito_verificacion
          ? this.guia.numero + this.guia.digito_verificacion
          : this.guia.numero;
        this.digito_verificacion = this.guia.digito_verificacion;
        this.estadoGuia = this.guia.estado;
      } else {
        this.formEnvio.guia_id = null;
        this.formEnvio.numeroGuia = null;
        this.estadoGuia = null;
      }
    },

    selectSitioOrigen() {
      // Se reinician las variables a modificar del formulario
      this.form.ciudadOrigen = null;
      this.formEnvio.sitio_origen_id = null;
      this.sitioDestino = null;
      this.formEnvio.sitio_destino_id = null;
      this.listasForms.producto_liquido = [];
      this.listasForms.tanques = [];
      this.formEnvio.producto_liquido_id = null;
      this.formEnvio.tanque_id = null;

      if (this.sitioOrigen) {
        this.formEnvio.sitio_origen_id = this.sitioOrigen.id;
        this.form.sitio_cargue_id = this.sitioOrigen.id;
        this.form.ciudadOrigen = this.sitioOrigen.ciudad.nombre;
        this.formEnvio.ciudad_origen_id = this.sitioOrigen.ciudad.id;
        this.getProductosLiquidos();
        this.buscarCategoria();
        this.listasForms.tanques = this.sitioOrigen.tanques;
        if (this.formEnvio.tipo_act !== 4) {
          this.buscarGuiaInput();
          this.buscarSellos();
        }
      }
    },

    async selectSitioDestino() {
      if (this.sitioDestino) {
        await this.checkRuta();
        await this.buscarCategoria();
      } else {
        this.formEnvio.sitio_destino_id = null;
        this.formEnvio.ciudad_destino_id = null;
        this.formEnvio.codigo_destino = null;
        this.formEnvio.direccion_destino = null;
        this.formEnvio.horas_vigencia = null;
        this.form.ciudadDestino = null;
      }
    },

    selectVentanaDescargue() {
      this.formEnvio.ventana_descargue_id = "";
      if (this.ventanaDescargue) {
        this.formEnvio.ventana_descargue_id = this.ventanaDescargue.id;
      }
    },

    validacionCategoria() {
      this.$swal({
        title: "Está seguro de asignar esta categoría.",
        text:
          "Por favor validar nuevamente y aceptar si está seguro, de lo contrario por favor cancelar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar!",
      }).then((result) => {
        if (!result.value) {
          this.formEnvio.categoria_operaciones_id = null;
        }
      });
    },

    buscarVentanasEnturneDescargue(search, loading) {
      if (search != "") {
        let me = this;
        axios
          .get(
            "api/hidrocarburos/detalleGuias/listaVentanas?ventana_enturnamiento=" +
              search,
            {
              params: {
                sitio_id: this.formEnvio.sitio_destino_id,
                sitio_type: 1,
              },
            }
          )
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.ventana_descargues = respuesta;
            me.formEnvio.ventana_descargue_id =
              me.listasForms.ventana_descargues.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectProductoLiquido() {
      let prod_liqui = this.listasForms.producto_liquido.find(
        (n) => n.id === this.formEnvio.producto_liquido_id
      );
    },

    getDespachado() {
      axios.get("/api/lista/54").then((response) => {
        this.listasForms.despachado_a = response.data;
      });
    },

    back() {
      if (
        this.$route.params.actoGuia == 2 ||
        this.$route.params.actoGuia == 3
      ) {
        // return this.$router.push({
        //   path: `/Hidrocarburos/VentanaOperaciones/${sitioID}/${sitioType}/${tipoControl}`,
        // });
        return this.$router.replace({
          path: `/Hidrocarburos/VentanasOperaciones/${this.sitio_id}/${this.sitio_type}/${this.tipo_control}`,
        });
      } else {
        return this.$router.replace("/Hidrocarburos/Viajes");
      }
    },

    async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/hidrocarburos/postulaciones/listaConductores";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/hidrocarburos/postulaciones/listaRemolques";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/hidrocarburos/postulaciones/listaVehiculos";
      }

      let params = {
        entidad,
        dato,
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos !== undefined) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
          erroHtml += "</div>";
          await this.$swal({
            html: erroHtml,
            showCancelButton: false,
            focusConfirm: true,
            icon: "error",
            title: "Aviso en documentacion",
            showConfirmButton: false,
            showCloseButton: true,
            footer:
              "<small style='text-align:center'><b>Desde el 1 de abril se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
          });
        } else {
          this.$swal({
            icon: "error",
            title: res.validacion.error.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    async buscarConductores() {
      this.cargando = true;
      let dato = this.formEnvio.cedula_conductor; //7222988
      let res = await this.validarStatus(dato, "C");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.cargando = false;
          if (res.validacion.error.documentos !== undefined) {
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined ||
              res.validacion.error.inactivo == false
            ) {
              this.formEnvio.cedula_conductor = null;
              this.formEnvio.conductor = {};
              this.conductor = {};
              return false;
            }
          }
          if (res.validacion.error.inactivo == false) {
            this.formEnvio.cedula_conductor = null;
            this.formEnvio.conductor = {};
            this.conductor = {};
            return false;
          }
        }
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formEnvio.cedula_conductor = null;
          this.formEnvio.conductor = {};
          this.conductor = {};
          return false;
        }
        if (this.formEnvio.vehiculo.tipo_combustible == 1) {
          if (res[0].gnv == null || res[0].gnv == 0) {
            await this.$swal({
              icon: "error",
              title:
                "La Empresa del conductor no se encuentra habilitada para Gnv.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.formEnvio.cedula_conductor = null;
            this.formEnvio.conductor = {};
            this.conductor = {};
            return false;
          }
        }
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El Conductor no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formEnvio.cedula_conductor = null;
          this.formEnvio.conductor = {};
          this.conductor = {};
          return false;
        }
        if (res.viaje.length > 0) {
          this.formEnvio.cedula_conductor = null;
          this.formEnvio.conductor = {};
          this.conductor = {};
          await this.$swal({
            icon: "error",
            title: `El conductor se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
        if (res.postulacion.length > 0) {
          this.formEnvio.cedula_conductor = null;
          this.formEnvio.conductor = {};
          this.conductor = {};
          await this.$swal({
            icon: "error",
            title: `El conductor cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
        if (res.postulacion_adicionales.length > 0) {
          this.formEnvio.cedula_conductor = null;
          this.formEnvio.conductor = {};
          this.conductor = {};
          await this.$swal({
            icon: "error",
            title: `El conductor ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }
        await this.$swal({
          icon: "success",
          title: "El conductor es apto..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await this.selectConductor(res[0]);
      } else {
        await this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.formEnvio.cedula_conductor = null;
        this.formEnvio.conductor = {};
        this.conductor = {};
      }
      this.cargando = false;
    },

    selectConductor(conductor) {
      if (this.formEnvio.cedula_conductor) {
        this.formEnvio.conductor = conductor;
        this.conductor = conductor;
        this.formEnvio.conductor_id = conductor.id;
        this.conductor.nombre = conductor.nombres + " " + conductor.apellidos;
      }
    },

    async buscarVehiculos() {
      this.cargando = true;
      let dato = this.vehiculo.placa;
      let res = await this.validarStatus(dato, "V"); //SVC962, THX689
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.cargando = false;
          await this.msgDocumentacion(res);
          if (res.validacion.error.documentos != undefined) {
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              this.vehiculo.placa = null;
              this.formEnvio.vehiculo_id = null;
              this.formEnvio.vehiculo = {};
              return false;
            }
          }
          if (res.validacion.error.inactivo == false) {
            this.vehiculo.placa = null;
            this.formEnvio.vehiculo_id = null;
            this.formEnvio.vehiculo = {};
            return false;
          }
        }

        if (res.viaje.length > 0) {
          this.vehiculo.placa = null;
          this.formEnvio.vehiculo_id = null;
          this.formEnvio.vehiculo = {};
          await this.$swal({
            icon: "error",
            title: `El vehículo se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        //Validar si ya se encuentra con una postulacion
        if (res.postulacion.length > 0) {
          this.vehiculo.placa = null;
          this.formEnvio.vehiculo_id = null;
          this.formEnvio.vehiculo = {};
          await this.$swal({
            icon: "error",
            title: `El vehículo cuenta con una postulación activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        //Validar si ya se encuentra con una postulacion adicionales
        if (res.postulacion_adicionales.length > 0) {
          this.vehiculo.placa = null;
          this.formEnvio.vehiculo_id = null;
          this.formEnvio.vehiculo = {};
          await this.$swal({
            icon: "error",
            title: `El vehículo ya está en un proceso de postulación de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        //Validar si el vehículo tiene peso asignado.
        if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.vehiculo.placa = null;
          this.formEnvio.vehiculo_id = null;
          this.formEnvio.vehiculo = {};
          await this.$swal({
            icon: "error",
            title: `El vehículo no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Vehículos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.vehiculo.placa = null;
          this.formEnvio.vehiculo_id = null;
          this.formEnvio.vehiculo = {};
          this.cargando = false;
          return false;
        }

        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El vehículo no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.vehiculo.placa = null;
          this.formEnvio.vehiculo_id = null;
          this.formEnvio.vehiculo = {};
          this.cargando = false;
          return false;
        }

        if (!res["linea_gnv"]) {
          await this.$swal({
            icon: "error",
            title: `El vehículo tiene combustible a GAS pero no se ha asignado línea vehícular`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.vehiculo.placa = null;
          this.formEnvio.vehiculo_id = null;
          this.formEnvio.vehiculo = {};
          this.cargando = false;
          return false;
        }

        //Valida Si la Empresa del vehiculo tiene check gnv
        if (res[0].tipo_combustible == 1) {
          if (res[0].empresa.gnv == null || res[0].empresa.gnv == 0) {
            this.vehiculo.placa = null;
            this.formEnvio.vehiculo_id = null;
            this.formEnvio.vehiculo = {};
            this.$swal({
              icon: "error",
              title:
                "La Empresa del vehículo no se encuentra habilitada para Gnv.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.cargando = false;
            return false;
          }
        }
        await this.$swal({
          icon: "success",
          title: "El vehículo es apto..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await this.selectVehiculo(res[0]);
      } else {
        this.$swal({
          icon: "error",
          title:
            "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.cargando = false;
        this.vehiculo.placa = null;
        this.formEnvio.vehiculo_id = null;
        this.formEnvio.vehiculo = {};
        return false;
      }
      this.cargando = false;
    },

    selectVehiculo(vehiculo) {
      if (this.vehiculo.placa) {
        this.vehiculo.placa = vehiculo.placa;
        this.formEnvio.vehiculo_id = vehiculo.id;
        this.formEnvio.vehiculo = vehiculo;
      }
    },

    async buscarRemolques() {
      this.cargando = true;
      let dato = this.remolque.placa;
      let res = await this.validarStatus(dato, "R");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.cargando = false;
          if (res.validacion.error.documentos !== undefined) {
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined ||
              res.validacion.error.inactivo == false
            ) {
              this.remolque.placa = null;
              this.formEnvio.remolque_id = null;
              this.formEnvio.remolque = {};
              this.cargando = false;
              return false;
            }
          }

          if (res.validacion.error.inactivo == false) {
            this.remolque.placa = null;
            this.formEnvio.remolque_id = null;
            this.formEnvio.remolque = {};
            this.cargando = false;
            return false;
          }
        }

        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Relmoques`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.remolque.placa = null;
          this.formEnvio.remolque_id = null;
          this.formEnvio.remolque = {};
          this.cargando = false;
          return false;
        }
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El Remolque no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.remolque.placa = null;
          this.formEnvio.remolque_id = null;
          this.formEnvio.remolque = {};
          this.cargando = false;
          return false;
        }

        if (res.viaje.length > 0) {
          this.remolque.placa = null;
          this.formEnvio.remolque_id = null;
          this.formEnvio.remolque = {};
          await this.$swal({
            icon: "error",
            title: `El remolque se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }
        if (res.postulacion.length > 0) {
          this.remolque.placa = null;
          this.formEnvio.remolque_id = null;
          this.formEnvio.remolque = {};
          await this.$swal({
            icon: "error",
            title: `El remolque cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }
        //Validar si el rermolque esta en una postulacion de adicionales.
        if (res.postulacion_adicionales.length > 0) {
          this.remolque.placa = null;
          this.formEnvio.remolque_id = null;
          this.formEnvio.remolque = {};
          await this.$swal({
            icon: "error",
            title: `El remolque ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }
        //Validar si el rermolque tiene peso asignado.
        if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.remolque.placa = null;
          this.formEnvio.remolque_id = null;
          this.formEnvio.remolque = {};
          await this.$swal({
            icon: "error",
            title: `El remolque no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }
        await this.$swal({
          icon: "success",
          title: "El remolque es apto..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await this.selectRemolque(res[0]);
      } else {
        this.$swal({
          icon: "error",
          title:
            "El remolque digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.remolque.placa = null;
        this.formEnvio.remolque_id = null;
        this.formEnvio.remolque = {};
      }

      this.cargando = false;
    },

    selectRemolque(remolque) {
      if (this.remolque.placa) {
        this.remolque.placa = remolque.placa;
        this.formEnvio.remolque_id = remolque.id;
        this.formEnvio.remolque = remolque;
      }
    },

    async buscarCategoria() {
      let me = this;
      if (
        me.formEnvio.sitio_origen_id &&
        me.formEnvio.sitio_destino_id &&
        me.accion == "Diligenciar"
      ) {
        me.cargando = true;
        let url = "api/hidrocarburos/detalleGuias/buscarCategoria";
        await axios
          .get(url, {
            params: {
              tipo_ruta: me.form.det_postulacion.det_solicitud_diaria.tipo_ruta,
              origen_id: me.formEnvio.sitio_origen_id,
              destino_id: me.formEnvio.sitio_destino_id,
              programacion_id:
                me.form.det_postulacion.det_solicitud_diaria.solicitud_diaria
                  .det_programacion_sitio.programacion_id,
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            if (response.data) {
              me.formEnvio.categoria_operaciones_id =
                respuesta.categoria_operacion;
            }
            me.cargando = false;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.cargando = false;
          });
      }
    },

    async getRuta() {
      let me = this;
      await axios
        .get("api/admin/rutasReconocer/lista")
        .then(function(response) {
          me.listasForms.rutasA = response.data;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectRutaA() {
      this.formEnvio.ruta_a_conocer_id = null;
      if (this.rutaA) {
        this.formEnvio.ruta_a_conocer_id = this.rutaA.id;
      }
    },
  },

  async beforeMount() {
    this.cargando = true;
    await this.init();
  },
};
</script>
